/* 
 * This CSS is used for the static error page / boundary in case the Theme / Config / Language
 * providers throw a JavaScript error. At that point we won't be able to retrieve the theme, styles and 
 * translations but we can still style this page similar to the other ones. */
html {
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
  overflow-y: scroll;
}

body {
  box-sizing: inherit;
  margin: 0;
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  min-height: 100%;
  height: 100%;
}

.static-error-page header {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: static;
  background-color: #eeeeeeff;
  color: #212121ff;
  height: 64px;
  -webkit-box-pack: center;
  justify-content: center;
}

.static-error-page nav {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  color: #212121ff;
  box-sizing: inherit;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-height: 64px;
}

.static-error-page main {
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.static-error-page section {
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.static-error-page h1 {
  color: #212121ff;
  text-align: center;
  box-sizing: inherit;
  margin: 0;
  font-weight: 300;
  font-size: 3.375rem;
  line-height: 4rem;
  letter-spacing: 0;
  font-family: 'Inter', sans-serif;
}

.static-error-page article {
  color: #212121ff;
  text-align: center;
  box-sizing: inherit;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
}

.static-error-page .logo-wrapper {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  color: #212121ff;
  box-sizing: inherit;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 0;
}

.static-error-page .logo-wrapper a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: inherit;
}

.static-error-page .logo-wrapper a img {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  text-align: center;
  box-sizing: inherit;
  vertical-align: middle;
  display: inline-block;
  max-width: 100px;
  max-height: 75%;
  width: auto;
  height: auto;
}

.static-error-page .header-wrapper {
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  margin: 0;
  flex-direction: row;
  border-bottom: 1px solid;
  border-color: #0000001f;
}

.static-error-page {
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
}

#root {
  color: #212121ff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  font-family: 'Inter', sans-serif;
  box-sizing: inherit;
  min-height: 100%;
  height: 100%;
}
